import { useEffect } from 'react';
import { useSiteStore } from '@/store/site';
import { useAccountInfo } from '@/hooks/query/account';
import { OneDomainService } from '@/services/onedomain.service';
import { useGetSiteConfigsWithSession } from '@/hooks/query/use-site-configs';
import { extractOneDomain } from '@nf/utils-common/url';

export const OneDomainProvider = () => {
	const { user } = useAccountInfo();
	const [apiDomain, setApiDomain, deepLinkSiteInfo] = useSiteStore(state => [state.apiDomain, state.setApiDomain, state.deepLinkSiteInfo]);
	const { SiteConfigsWithSessionData } = useGetSiteConfigsWithSession();

	useEffect(() => {
		const accountStatus = user?.ActStatus ?? 0;

		const { protocol, hostname } = location;

		const firstDotIndex = hostname.indexOf('.');

		if (firstDotIndex > -1) {
			const oneDomainForWeb = hostname.slice(0, firstDotIndex);
			const [randomId, webFunctionId, uatEnvironment] = extractOneDomain(oneDomainForWeb);
			const enableMainDomainOnPremise = (SiteConfigsWithSessionData?.MainDomainOnPremise ?? '') != '';
			const mainDomain = ['gc', 'go', 'gd'].indexOf(webFunctionId) != -1 && enableMainDomainOnPremise ? SiteConfigsWithSessionData?.MainDomainOnPremise : hostname.slice(firstDotIndex + 1);
			const oneDomainForApi = OneDomainService.getOneDomainForApi(oneDomainForWeb, accountStatus, enableMainDomainOnPremise);

			setApiDomain(`${protocol}//${oneDomainForApi}.${mainDomain}`);
		} else {
			setApiDomain(`${protocol}//k957ul002.hl5888.com`);
		}
	}, [user?.ActStatus, SiteConfigsWithSessionData?.MainDomainOnPremise]);
	return null;
};